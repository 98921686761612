<template>
    <div>

        <section class="l-welcome l-guide">
            <h1 class="l-welcome-ttl">講師登録から出品までの流れ</h1>
            <ol class="l-step">

                <li class="l-section"> <i class="no">Step</i>
                    <div class="flex lp-section--inner">
                        <div class="figcaption">
                            <h2 class="lp-section--title">会員登録</h2>
                            <p>画面右上の「会員登録」よりニックネーム、メールアドレス、パスワード、電話番号を入力して登録。</p>
                        </div>
                        <div class="lp-section--img object-center">
                            <button @click="_register" class="btn-register btn-radius">会員登録<span
                                    class="sub">（無料）</span></button>
                        </div>
                    </div>
                </li>

                <li class="l-section"> <i class="no">Step</i>
                    <div class="flex lp-section--inner">
                        <div class="figcaption">
                            <h2 class="lp-section--title">プロフィール設定</h2>
                            <p>画面右上の「マイページ」をクリックし、右カラムの「自己紹介編集」より自己紹介を入力します。<br>ご自身の経歴や対応可能な相談内容などをご入力ください。</p>
                        </div>
                        <div class="lp-section--img">
                            <figure><img src="/img/guide/reg02.png" alt="プロフィール設定"></figure>
                        </div>
                    </div>
                </li>

                <li class="l-section"> <i class="no">Step</i>
                    <div class="flex lp-section--inner">
                        <div class="figcaption">
                            <h2 class="lp-section--title">講師に応募する</h2>
                            <p>画面上部のメニュー右側の「メニューを登録（相談員）」またはトップページ下部「講師に応募する」より「相談を出品したい場合」のページに遷移。「講師に応募する」ボタンから登録フォームに移動します。
                            </p>
                            <p class="at">※<a href="/terms" target="_blank" rel="noopener">利用規約</a>および<a
                                    href="/privacy-policy" target="_blank" rel="noopener">個人情報の取り扱いについて</a>をご一読ください。</p>
                        </div>
                        <div class="lp-section--img">
                            <figure><img src="/img/guide/reg03.png" alt="講師に応募する"></figure>
                        </div>
                    </div>
                </li>

                <li class="l-section"> <i class="no">Step</i>
                    <div class="flex lp-section--inner">
                        <div class="figcaption">
                            <h2 class="lp-section--title">講師登録フォーム</h2>
                            <p>氏名、郵便暗号、都道府県、以降の住所、自己紹介、得意ジャンル、および可能な限りSNS情報を入力いただき、「送信」ボタンをクリックします。</p>
                            <p>送信ボタンをクリック後、決済サービス「Stripe」の認証画面に遷移します。</p>
                        </div>
                        <div class="lp-section--img">
                            <figure><img src="/img/guide/reg04.png" alt="講師登録フォーム"></figure>
                        </div>
                    </div>
                </li>

                <li class="l-section"> <i class="no">Step</i>
                    <div class="flex lp-section--inner">
                        <div class="figcaption-column">
                            <h2 class="lp-section--title">Stripeによる本人認証</h2>
                            <p>個人情報と振込用銀行口座をご登録いただきます。免許書などの身分証明書をご準備いただく必要があります。</p>
                            <p class="at">※Stripe（<a href="https://stripe.com/jp" target="_blank"
                                    rel="noopener">https://stripe.com/jp</a>）は、世界中の数百万の企業が導入しているオンライン決済サービスです。</p>
                        </div>
                    </div>
                </li>

                <li class="l-section"> <i class="no">Step</i>
                    <div class="flex lp-section--inner">
                        <div class="figcaption-column">
                            <h2 class="lp-section--title">審査中</h2>
                            <p>Stripeでの審査完了後に、入力内容をもとに、編集部で講師審査させていただきます。審査には最大7営業日を頂戴しております。</p>
                        </div>
                    </div>
                </li>

                <li class="l-section"> <i class="no">Step</i>
                    <div class="flex lp-section--inner">
                        <div class="figcaption-column">
                            <h2 class="lp-section--title">審査完了後出品機能が付与</h2>
                            <p>無事講師登録が完了したら、サポートセンターよりメールにて通知します。</p>
                            <p>出品（相談受付／相談）については、メールに記載されているURLおよびページヘッダー「メニューを登録（相談員）」、マイページの出品一覧より登録してください。</p>
                        </div>
                    </div>
                </li>

                <li class="l-section"> <i class="no">Step</i>
                    <div class="flex lp-section--inner">
                        <div class="figcaption">
                            <h2 class="lp-section--title">メニューを登録/マイページの出品一覧から相談を登録</h2>
                            <p>タイトル、本文、価格、サムネイル画像、カテゴリ（親/子）、受付枠数を入力し、ステータスを「販売中」に設定して「更新」をクリックすることで出品登録が完了します。</p>
                            <p class="at">
                                ※登録価格からStripe決済手数料およびサービス利用料を差し引いた70%を報酬として、Stripeにご登録いただいた銀行口座へお振込みいたします。相談完了後、前週火曜日から翌週月曜日までの相談費用を金曜日に送金致します。<br />
                                (銀行の処理時間によって、入金には数営業日かかる場合があります)
                            </p>
                        </div>
                        <div class="lp-section--img">
                            <figure><img src="/img/guide/reg08.png" alt="登録画面"></figure>
                        </div>
                    </div>
                </li>

            </ol>
        </section>
    </div>
</template>

<script>
export default {

    methods: {
        _register() {
            if(this.isMember) {
                window.$("#mms-edit-profile").click();
            }else {
                window.$("#mms-sign-up").click();
            }
        },
    }
}
</script>

<style></style>